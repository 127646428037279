// this will have annotations form
import { NavigateBefore, NavigateNext } from '@mui/icons-material'
import { Button, ButtonGroup, Grid, Input, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { navigate } from 'gatsby'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

const AnnotationSection = ({ annotations, onAnnotate, currenctClinician, loadTargetBowel }) => {
	const [activeImageIndex, setActiveImageIndex] = useState(0)
	const [clinicianAnnotations, setClinicianAnnotations] = useState({
		volume: '',
		bristol: '',
		consistency: '',
		fragmentation: '',
		edge_fuzziness: '',
		colon_ready: '',
	})

	const media = useMemo(() => {
		return annotations?.targetBowel?.media
	}, [annotations])

	const labels = useMemo(() => {
		return annotations?.targetBowel?.['_labels']
	}, [annotations])

	const bowelId = useMemo(() => {
		return annotations?.targetBowel?.bowel_id
	}, [annotations])

	const onClickPrevious = useCallback(() => {
		if (activeImageIndex - 1 >= 0) {
			setActiveImageIndex(activeImageIndex - 1)
		}
	}, [activeImageIndex])

	const onClickNext = useCallback(() => {
		if (activeImageIndex + 1 < media?.length) {
			setActiveImageIndex(activeImageIndex + 1)
		}
	}, [activeImageIndex])

	const onClickCancel = () => {
		loadTargetBowel({})
		navigate('..')
	}

	const handleChange = useCallback(
		(ev) => {
			setClinicianAnnotations({
				...clinicianAnnotations,
				[ev.target.name]: parseFloat(ev.target.value),
			})
		},
		[clinicianAnnotations]
	)

	const onClickSave = useCallback(() => {
		onAnnotate(bowelId, clinicianAnnotations)
		// uncomment if you want to go back
		// loadTargetBowel({})
		// navigate('..')
	}, [clinicianAnnotations])

	const usersExistingAnnotation = useMemo(() => {
		const allAnnotations = annotations?.annotations?.annotations
		const target = annotations?.targetBowel
		const existing = allAnnotations?.find(
			(annotation) =>
				annotation?.bowel_id === target?.bowel_id && annotation?.clinician_id === currenctClinician?.admin?.id
		)

		return existing
	}, [annotations])

	useEffect(() => {
		const existingAnnotation = usersExistingAnnotation

		if (existingAnnotation && existingAnnotation?.clinician_annotation) {
			const { volume, bristol, consistency, fragmentation, edge_fuzziness, colon_ready } =
				existingAnnotation?.clinician_annotation
			setClinicianAnnotations({
				volume: volume || '0',
				bristol: bristol || '0',
				consistency: consistency || '0',
				fragmentation: fragmentation || '0',
				edge_fuzziness: edge_fuzziness || '0',
				colon_ready: colon_ready || '0',
			})
		}
	}, [annotations])

	return (
		<Stack gap={2}>
			<Grid container mt={2}>
				<Grid item xs={7}>
					<Grid container pt={3}>
						<Grid item xs={6}>
							<Typography mb={2}>Clinician&apos;s Annotation</Typography>
							<Stack pr={3} gap={2}>
								<Tooltip title="Indicates generally how large the stool is by total volume">
									<TextField
										onChange={handleChange}
										label="Volume"
										name="volume"
										value={clinicianAnnotations?.volume}
										variant="outlined"
										type="number"
									/>
								</Tooltip>
								<Tooltip>
									<TextField
										onChange={handleChange}
										label="Bristol"
										name="bristol"
										value={clinicianAnnotations?.bristol}
										variant="outlined"
										type="number"
									/>
								</Tooltip>
								<Tooltip title="Indicates generally how liquid or solid the stool is">
									<TextField
										onChange={handleChange}
										label="Consistency"
										name="consistency"
										value={clinicianAnnotations?.consistency}
										variant="outlined"
										type="number"
									/>
								</Tooltip>
								<Tooltip title="Indicates generally how divided the stool is into separate pieces">
									<TextField
										onChange={handleChange}
										label="Fragmentation"
										name="fragmentation"
										value={clinicianAnnotations?.fragmentation}
										variant="outlined"
										type="number"
									/>
								</Tooltip>
								<Tooltip title="Indicates generally how clear or fuzzy the edges of the stool are">
									<TextField
										onChange={handleChange}
										label="Edge Fuzziness"
										name="edge_fuzziness"
										value={clinicianAnnotations?.edge_fuzziness}
										variant="outlined"
										type="number"
									/>
								</Tooltip>
								<Tooltip title="Indicates how ready the patient is for their colonoscopy">
									<TextField
										onChange={handleChange}
										label="Colonoscopy Readiness"
										name="colon_ready"
										value={clinicianAnnotations?.colon_ready}
										variant="outlined"
										type="number"
									/>
								</Tooltip>
							</Stack>
						</Grid>
						<Grid item xs={6}>
							<Typography mb={2}>AI&apos;s Annotation</Typography>
							<Stack pr={3} gap={2}>
								<TextField
									label="Volume"
									value={labels?.volume}
									disabled
									variant="outlined"
									type="number"
								/>
								<TextField
									label="Bristol"
									value={labels?.bristol}
									disabled
									variant="outlined"
									type="number"
								/>
								<TextField
									label="Consistency"
									value={labels?.consistency}
									disabled
									variant="outlined"
									type="number"
								/>
								<TextField
									label="Fragmentation"
									value={labels?.fragmentation}
									disabled
									variant="outlined"
									type="number"
								/>
								<TextField
									label="Edge Fuzziness"
									value={labels?.edge_fuzziness}
									disabled
									variant="outlined"
									type="number"
								/>
								<Stack gap={1} direction={'row'} mt={3} justifyContent={'flex-end'}>
									<Button onClick={onClickCancel} variant="outlined">
										Cancel
									</Button>
									<Button onClick={onClickSave} variant="contained">
										Save
									</Button>
								</Stack>
							</Stack>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={5}>
					<Stack position={'relative'}>
						<img
							src={media?.[activeImageIndex]?.uri}
							width={'100%'}
							style={{ borderRadius: 10, userSelect: 'none' }}
						/>
						<ButtonGroup style={{ position: 'absolute', bottom: 10, left: 10 }} variant="contained">
							<Button onClick={onClickPrevious} disabled={activeImageIndex == 0}>
								<NavigateBefore />
							</Button>
							<Button onClick={onClickNext} disabled={activeImageIndex + 1 == media?.length}>
								<NavigateNext />
							</Button>
						</ButtonGroup>
						<Typography
							align="center"
							position={'absolute'}
							bgcolor={'rgba(255,255,255,0.7)'}
							top={10}
							right={10}
							px={1.5}
							py={0.3}
							borderRadius={20}
						>
							{activeImageIndex + 1}/{media?.length}
						</Typography>
					</Stack>
				</Grid>
			</Grid>
			<Stack direction={'row'} justifyContent={'flex-end'} gap={1}>
				{media?.map((image, index) => (
					<img
						key={image?.uri}
						src={image?.uri}
						onClick={() => setActiveImageIndex(index)}
						style={{
							width: '70pt',
							borderRadius: 10,
							cursor: 'pointer',
							border: activeImageIndex === index ? '2pt solid #8F82F4' : '3pt solid transparent',
							boxShadow: activeImageIndex === index ? '0px 0px 5px gray' : '',
						}}
					/>
				))}
			</Stack>
		</Stack>
	)
}

export default AnnotationSection
