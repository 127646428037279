import React, { useMemo } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import * as actions from 'actions'
import { connect } from 'react-redux'
import Grid from '@mui/material/Grid'
import AnnotationSection from './AnnotationSection'
import AllAnnotations from './AllAnnotations'
import Content from '@components/Content'
import { Typography, CircularProgress, Stack } from '@mui/material'

const PREFIX = 'index'

const classes = {
	root: `${PREFIX}-root`,
	roleContainer: `${PREFIX}-roleContainer`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(4),
	},

	[`& .${classes.roleContainer}`]: {
		paddingTop: theme.spacing(4),
	},
}))

const AnnotationsContent = ({ annotations, clinician, putAnnotationForBowel, loadAnnotationsFor, loadTargetBowel }) => {
	const flatAnnotations = useMemo(() => {
		return annotations?.annotations?.annotations?.map((annotation) => ({
			...annotation,
			...annotation?.clinician_annotation,
		}))
	}, [annotations])

	const onAnnotate = (bowelId, annotation) => {
		if (bowelId && annotation) {
			putAnnotationForBowel(bowelId, clinician?.admin?.id, annotation)
			loadAnnotationsFor(annotations?.targetBowel?.uuid)
		}
	}

	return (
		<Root className={classes.root}>
			<Typography fontSize={15} textAlign={'right'}></Typography>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<AnnotationSection
						currenctClinician={clinician}
						annotations={annotations}
						loadTargetBowel={loadTargetBowel}
						onAnnotate={onAnnotate}
					/>
				</Grid>
			</Grid>

			<Grid container spacing={4} mt={2}>
				{!annotations?.loading ? (
					<Grid item xs={12}>
						{flatAnnotations?.length ? (
							<AllAnnotations annotations={annotations?.targetBowel?.uuid ? flatAnnotations : []} />
						) : (
							<Content
								title={'No Annotations for this Entry!'}
								subtitle={'As soon as clinicians annotate, they will appear here'}
							/>
						)}
					</Grid>
				) : (
					<Stack justifyContent={'center'} flexDirection={'row'} width={'100%'} p={3}>
						<CircularProgress />
					</Stack>
				)}
			</Grid>
		</Root>
	)
}

AnnotationsContent.propTypes = {
	annotations: PropTypes.object,
	clinician: PropTypes.object,
	putAnnotationForBowel: PropTypes.func.isRequired,
	loadAnnotationsFor: PropTypes.func.isRequired,
	loadTargetBowel: PropTypes.func.isRequired,
}

const mapStateToProps = ({ annotations, clinician, loadAnnotationsFor, loadTargetBowel }) => {
	return {
		annotations,
		clinician,
		loadAnnotationsFor,
		loadTargetBowel,
	}
}

export default connect(mapStateToProps, actions)(AnnotationsContent)
